<template>
    <el-scrollbar class="data-center">
        <div class="data-content">
            <div class="top-title">
                <!--                <img src="../../../assets/images/evaluationCenter/green-background.png" alt="">-->
                <!--                <span class="text">考试数据鉴定中心考试数据看板</span>-->
            </div>
            <div class="filter-box">
                <el-date-picker v-model="month" type="month" placeholder="选择月" value-format="yyyy-MM"
                                @change="changeMonth"></el-date-picker>
                <div class="filter-right">
                    <span class="right-text">数据更新时间：{{time}}</span>
                    <i class="iconfont" @click="refreshData">&#xe606;</i>
                </div>
            </div>
            <div class="middle-content">
                <div class="left-box">
                    <div class="data-card icon-card">
                        <div class="card-item">
                            <span class="item-title">考场</span>
                            <div class="card-content">
                                <div class="icon-box">
                                    <img src="../../../assets/images/evaluationCenter/examination-room.png" alt="">
                                </div>
                                <div class="bottom-data">
                                    <div class="data-num blue">{{examInfo.exam_room_num?examInfo.exam_room_num:0}}</div>
                                    <div class="data-unit">场</div>
                                </div>
                            </div>
                        </div>
                        <div class="card-item">
                            <span class="item-title">考试场数</span>
                            <div class="card-content">
                                <div class="icon-box">
                                    <img src="../../../assets/images/evaluationCenter/exam-num.png" alt="">
                                </div>
                                <div class="bottom-data">
                                    <div class="data-num green">{{examInfo.exam_num?examInfo.exam_num:0}}</div>
                                    <div class="data-unit">场</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data-card icon-card">
                        <div class="card-item">
                            <span class="item-title">理论考试</span>
                            <div class="card-content">
                                <div class="icon-box">
                                    <img src="../../../assets/images/evaluationCenter/theory-exam.png" alt="">
                                </div>
                                <div class="bottom-data">
                                    <div class="data-num yellow">
                                        {{examInfo.theory_exam_num?examInfo.theory_exam_num:0}}
                                    </div>
                                    <div class="data-unit">场</div>
                                </div>
                            </div>
                        </div>
                        <div class="card-item">
                            <span class="item-title">实操考试</span>
                            <div class="card-content">
                                <div class="icon-box">
                                    <img src="../../../assets/images/evaluationCenter/operation-exam.png" alt="">
                                </div>
                                <div class="bottom-data">
                                    <div class="data-num pink">{{examInfo.opt_exam_num?examInfo.opt_exam_num:0}}</div>
                                    <div class="data-unit">场</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="middle-box">
                    <div class="data-card">
                        <div class="filter-box">
                            <div class="left-filter">
                                <el-select v-model="selectedRoom" placeholder="请选择" @change="selectRoom" size="small">
                                    <el-option
                                            v-for="item in examRoom"
                                            :key="item.exam_room_id"
                                            :label="item.exam_room_name"
                                            :value="item.exam_room_id">
                                    </el-option>
                                </el-select>
                                <el-select v-model="selectedExam" placeholder="请选择" @change="selectExam" size="small">
                                    <el-option
                                            v-for="item in examList"
                                            :key="item.id"
                                            :label="item.exam_name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <span class="filter-title">考试分数比例</span>
                        </div>
                        <div class="top-exam" id="examScoreRate"></div>
                    </div>
                </div>
                <div class="left-box">
                    <div class="data-card icon-card">
                        <div class="card-item">
                            <span class="item-title">已报名学生</span>
                            <div class="card-content">
                                <div class="icon-box">
                                    <img src="../../../assets/images/evaluationCenter/student-green.png" alt="">
                                </div>
                                <div class="bottom-data">
                                    <div class="data-num blue">{{examInfo.student_num?examInfo.student_num:0}}</div>
                                    <div class="data-unit">名</div>
                                </div>
                            </div>
                        </div>
                        <div class="card-item">
                            <span class="item-title">已考完试学生</span>
                            <div class="card-content">
                                <div class="icon-box">
                                    <img src="../../../assets/images/evaluationCenter/student-blue.png" alt="">
                                </div>
                                <div class="bottom-data">
                                    <div class="data-num blue">
                                        {{examInfo.exam_in_stu_num_before?examInfo.exam_in_stu_num_before:0}}
                                    </div>
                                    <div class="grey-num">
                                        /{{examInfo.exam_in_stu_num_after?examInfo.exam_in_stu_num_after:0}}
                                    </div>
                                    <div class="data-unit">名</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data-card icon-card">
                        <div class="card-item">
                            <span class="item-title">综合考试</span>
                            <div class="card-content">
                                <div class="icon-box">
                                    <img src="../../../assets/images/evaluationCenter/comprehensive-exam.png" alt="">
                                </div>
                                <div class="bottom-data">
                                    <div class="data-num yellow">
                                        {{examInfo.multiple_exam_num?examInfo.multiple_exam_num:0}}
                                    </div>
                                    <div class="data-unit">场</div>
                                </div>
                            </div>
                        </div>
                        <div class="card-item">
                            <span class="item-title">考场审核通过率</span>
                            <div class="card-content">
                                <div class="icon-box">
                                    <img src="../../../assets/images/evaluationCenter/pass-rate.png" alt="">
                                </div>
                                <div class="bottom-data">
                                    <div class="data-num green">{{examInfo.exam_room_rate?examInfo.exam_room_rate:0}}
                                    </div>
                                    <div class="data-unit">%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-content">
                <div class="bottom-card data-card">
                    <div class="filter-box">
                        <span class="filter-title">各科及格率</span>
                    </div>
                    <div class="pass-rate" id="passRate" style="width: 100%;height: 340px"></div>
                </div>
                <div class="bottom-card data-card">
                    <div class="filter-box">
                        <div class="left-filter"></div>
                        <span class="filter-title">平均分</span>
                    </div>
                    <div class="average-rate" id="averageScore" style="width: 100%;height: 340px"></div>
                </div>
                <div class="bottom-right data-card">
                    <div class="filter-box">
                        <div class="left-filter"></div>
                        <span class="filter-title">考试成绩排名</span>
                    </div>
                    <div class="table-box">
                        <el-table :data="examRankList" style="width: 100%" height="340px" size="medium"
                                  :header-cell-style="{fontWeight: 'normal', color: '#40C482', background: '#E7F6EF',fontSize: '14px',height:'30px'}"
                                  :cell-style="{fontSize: '14px',color: '#888888',height: '30px'}">
                            <el-table-column prop="nickname" label="排名" align="center" width="50">
                                <template slot-scope="scope">
                                    <img v-if="scope.$index + 1 === 1"
                                         :src="require('../../../assets/images/evaluationCenter/rank-1.png')" alt="">
                                    <img v-else-if="scope.$index + 1 === 2"
                                         :src="require('../../../assets/images/evaluationCenter/rank-2.png')" alt="">
                                    <img v-else-if="scope.$index + 1 === 3"
                                         :src="require('../../../assets/images/evaluationCenter/rank-3.png')" alt="">
                                    <span v-else>{{scope.$index + 1}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="nickname" label="名称" align="center"></el-table-column>
                            <el-table-column prop="exam_name" label="本场考试科目" align="center"></el-table-column>
                            <el-table-column prop="total_score" label="分数" align="center"></el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import {checkCenter_monthForExamRoom, checkCenter_dataCenter, studentlevel} from '@/utils/apis'
    import echarts from "echarts";

    export default {
        name: "Index",
        data() {
            return {
                month: '',
                time: '',
                examRoom: [],
                selectedRoom: '',
                selectedExam: '',
                examList: [],
                exam_type: 1,
                examInfo: {},
                exam_score_rate: [],
                exam_pass_rate: [],
                exam_avg_score: [],
                examRankList: [],
                examScoreRate: null,
                passRate: null,
                averageScore: null,
            }
        },
        mounted() {
            this.time = this.getNowTime()
            let year = new Date().getFullYear()
            let month = new Date().getMonth() + 1
            this.month = year + '-' + month
            this.getExaminationRoom()
            this.$nextTick(() => {
                this.examScoreRate = this.$echarts.init(document.getElementById('examScoreRate'));
                this.passRate = this.$echarts.init(document.getElementById('passRate'));
                this.averageScore = this.$echarts.init(document.getElementById('averageScore'));
                let resizeTimer = null;
                window.onresize = () => {
                    clearTimeout(resizeTimer);
                    resizeTimer = setTimeout(() => {
                        this.examScoreRate.resize();
                        this.passRate.resize();
                        this.averageScore.resize();
                    }, 100)
                }
            })
        },
        methods: {
            refreshData() {
                if (this.selectedRoom) {
                    this.time = this.getNowTime()
                    this.getExamDetail()
                } else {
                    this.$message.warning('请先选择考场！')
                }
            },
            // 获取当前时间
            getNowTime() {
                let dateTime
                let yy = new Date().getFullYear()
                let mm = new Date().getMonth() + 1
                let dd = new Date().getDate()
                let hh = new Date().getHours()
                let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes()
                    :
                    new Date().getMinutes()
                let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds()
                    :
                    new Date().getSeconds()
                dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
                return dateTime
            },
            getExaminationRoom() {
                checkCenter_monthForExamRoom({month: this.month}).then(res => {
                    if (res.code == 200) {
                        this.examRoom = res.data
                        this.getFirstId()
                    }
                })
            },
            changeMonth(val) {
                if (!val) {
                    this.$message.warning('月份不能为空！')
                    return
                }
                this.selectedRoom = ''
                this.selectedExam = ''
                this.getExaminationRoom()
                this.getExamDetail()
            },
            getFirstId() {
                let len = this.examRoom.length
                for (let i = 0; i < len; i++) {
                    if (this.examRoom[i].exam_info && this.examRoom[i].exam_info.length > 0) {
                        let len1 = this.examRoom[i].exam_info.length
                        for (let j = 0; j < len1; j++) {
                            if (this.examRoom[i].exam_info[j].id) {
                                this.selectedRoom = this.examRoom[i].exam_room_id
                                this.selectedExam = this.examRoom[i].exam_info[j].id
                                this.examList = this.examRoom[i].exam_info
                                this.getExamDetail()
                                break
                            }
                        }
                        if (this.selectedExam) {
                            break
                        }
                    }
                }
            },
            selectRoom(val) {
                this.selectedExam = ''
                this.examRoom.forEach(item => {
                    if (item.exam_room_id == val) {
                        this.examList = item.exam_info
                    }
                })
                this.getExamDetail()
            },
            selectExam(val) {
                this.getExamDetail()
            },
            getExamDetail() {
                let params = {
                    month: this.month,
                    exam_type: this.exam_type,
                }
                if (this.selectedRoom) {
                    params.exam_room = this.selectedRoom
                }
                if (this.selectedExam) {
                    params.exam_id = this.selectedExam
                }
                checkCenter_dataCenter(params).then(res => {
                    if (res.code == 200) {
                        this.examInfo = res.data.exam_info
                        this.examInfo.exam_in_stu_num_before = res.data.exam_info.exam_in_stu_num[0]
                        this.examInfo.exam_in_stu_num_after = res.data.exam_info.exam_in_stu_num[1]
                        this.exam_score_rate = res.data.exam_score_rate
                        this.exam_pass_rate = res.data.exam_pass_rate
                        this.exam_avg_score = res.data.exam_avg_score
                        this.examRankList = res.data.exam_score_rank
                        this.setExamNumRate()
                        this.setPassRate()
                        this.setAverageScore()
                    }
                })
            },
            selectType(val) {
                if (this.selectedRoom) {
                    this.getExamDetail()
                } else {
                    this.$message.warning('请先选择考场！')
                }
            },
            setExamNumRate() {
                let arr = []
                if (this.exam_score_rate.length > 0) {
                    arr = this.exam_score_rate.map(item => {
                        let obj = {
                            value: item.rate,
                            name: item.title
                        }
                        return obj
                    })
                }
                this.examScoreRate.setOption(
                    {
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            bottom: 10,
                            formatter(params) {
                                return '分数:' + params
                            }
                        },
                        series: [
                            {
                                name: '',
                                type: 'pie',
                                radius: '70%',
                                data: arr,
                                emphasis: {
                                    itemStyle: {
                                        borderRadius: 20,
                                        borderColor: '#fff',
                                        borderWidth: 6
                                    },
                                },
                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            //自定义颜色
                                            let colorList = [
                                                '#40C482', '#FE9E1E', '#19C2FE', '#438CF5', '#F97780',
                                            ];
                                            return colorList[params.dataIndex]
                                        }
                                    }
                                },
                                label: {
                                    normal: {
                                        show: true,
                                        formatter: "{d}% ",
                                        textStyle: {
                                            fontSize: '15px',
                                            color: "#333333"
                                        },
                                    },
                                },
                            }
                        ]
                    }
                )
            },
            setPassRate() {
                let dataX = [], dataY = [];
                if (this.exam_pass_rate.length > 0) {
                    this.exam_pass_rate.forEach(item => {
                        dataX.push(item.exam_name)
                        dataY.push(item.pass_rate)
                    })
                }
                this.passRate.setOption(
                    {
                        color: '#40C482',
                        tooltip: {
                            borderWidth: "1", //边框宽度设置1
                            borderColor: "#888",
                            backgroundColor: '#fff',
                            textStyle: {
                                color: "#666666" //设置文字颜色
                            },
                            formatter: params => {
                                let html;
                                html = params.name + '<br />' + '及格率:' + params.value;
                                return html;
                            },
                        },
                        grid: { //图表和父盒子之间的距离
                            left: '3%',
                            right: '10%',
                            bottom: '3%',
                            top: '20%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            data: dataX,
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#888'
                                }
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLabel: {
                                interval: 0,
                                textStyle: {
                                    color: '#333',
                                    fontSize: 14
                                },
                                margin: 8,
                                rotate: -38
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#888'
                                }
                            },
                            axisTick: {
                                show: false,
                            }
                        },
                        series: [
                            {
                                data: dataY,
                                type: 'bar',
                                barWidth: 30,
                            }
                        ]
                    }
                )
            },
            setAverageScore() {
                let dataX = [], dataY = [];
                if (this.exam_avg_score.length > 0) {
                    this.exam_avg_score.forEach(item => {
                        dataX.push(item.exam_name)
                        dataY.push(item.avg_score)
                    })
                }
                this.averageScore.setOption(
                    {
                        color: '#2DC079',
                        tooltip: {
                            borderWidth: "1", //边框宽度设置1
                            borderColor: "#888",
                            backgroundColor: '#fff',
                            textStyle: {
                                color: "#666666" //设置文字颜色
                            },
                            formatter: params => {
                                let html;
                                html = params.name + '<br />' + '成绩:' + params.value;
                                return html;
                            },
                        },
                        grid: { //图表和父盒子之间的距离
                            left: '3%',
                            right: '10%',
                            bottom: '3%',
                            top: '20%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            data: dataX,
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#888'
                                }
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLabel: {
                                interval: 0,
                                textStyle: {
                                    color: '#333',
                                    fontSize: 14
                                },
                                margin: 8,
                                rotate: -38
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#888'
                                }
                            },
                            axisTick: {
                                show: false,
                            }
                        },
                        series: [
                            {
                                data: dataY,
                                type: 'line'
                            }
                        ]
                    }
                )
            }
        }
    }
</script>

<style scoped lang="scss">
    .data-center {
        position: fixed;
        top: 60px;
        left: 210px;
        right: 0;
        bottom: 0;
        background: #F6F7FC;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                height: calc(100% - 40px);
                margin: 20px;
            }
        }
    }

    .data-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        .top-title {
            height: 50px;
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;

            .text {
                position: absolute;
                line-height: 50px;
                text-align: center;
                font-size: 20px;
                color: #fff;
            }
        }
    }

    .filter-box {
        display: flex;
        justify-content: space-between;

        .filter-right {

            .right-text {
                font-size: 17px;
                font-weight: bold;
                color: #666666;
                margin-right: 15px;
            }

            i {
                font-size: 21px;
                color: #2DC079;
                cursor: pointer;
            }
        }
    }

    .middle-content {
        margin-top: 15px;
        display: flex;

        .left-box {
            width: 25%;
        }

        .middle-box {
            width: 50%;
            height: 100%;
            margin: 0 30px;

            .data-card {
                height: 100%;
                display: flex;
                flex-direction: column;

                .top-exam {
                    width: 100%;
                    flex: 1;
                    height: 1%;
                }
            }
        }
    }

    .bottom-content {
        margin-top: 30px;
        padding-bottom: 30px;
        display: flex;

        .bottom-left {
            width: 33%;
        }

        .bottom-card {
            flex: 1;

            &:nth-of-type(2) {
                margin: 0 30px;
            }
        }

        .bottom-right {
            width: 35%;

            .table-box {
                height: 340px;
                padding: 20px 0;
                margin: 0 30px;
            }
        }
    }

    .data-card {
        background: #FFFFFF;
        box-shadow: 0px 4px 14px 0px rgba(27, 73, 52, 0.1);
        border-radius: 6px;

        .filter-box {
            padding: 20px 0;
            margin: 0 30px;
            border-bottom: 1px solid #EEEEEE;
            align-items: center;

            .el-select {
                width: 150px
            }

            .el-select + .el-select {
                margin-left: 22px;
            }

            .filter-title {
                font-weight: bold;
                color: #696969;
            }
        }
    }

    .icon-card {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &:last-child {
            margin-top: 30px;
        }

        .card-item {
            width: 50%;
            padding: 0 20px;
            margin: 20px 0;

            &:first-child {
                border-right: 1px solid #EEEEEE;
            }

            .item.title {
                font-weight: bold;
                color: #696969;
            }

            .card-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 22px;

                .icon-box {
                    width: 65px;
                    height: 65px;
                    background: #EEEEEE;
                    box-shadow: 0px 3px 4px 0px rgba(27, 73, 52, 0.15);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .bottom-data {
                    display: flex;
                    margin: 20px 0 0 20px;
                    align-items: flex-end;

                    .data-num {
                        font-size: 56px;
                        font-weight: bold;

                        &.blue {
                            color: #438CF5;
                        }

                        &.green {
                            color: #40C482;
                        }

                        &.yellow {
                            color: #FE9E1E;
                        }

                        &.pink {
                            color: #F97780;
                        }
                    }

                    .grey-num {
                        font-size: 30px;
                        color: #767676;
                        line-height: 45px;
                    }

                    .data-unit {
                        margin-left: 11px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #767676;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
</style>